<template>
  <div>
    <a-card :bordered="false" class="card-product header-solid mb-24">
      <template #title>
        <h5>合约打新</h5>
      </template>

      <div>
        <a-form
            :form="form"
            :hideRequiredMark="true"
            style="width: 70%"
        >
          <a-row :gutter="[24]" type="flex" justify="start">
            <a-col :span="14">
              <a-form-item class="mb-10" label="投资额（用于打新的资金）" :colon="false">
                <a-input placeholder=">10 注意：金额过低可能会无法开单" prefix="$" suffix="USDT"
                         v-decorator="['investment',{ rules: [
                             { required: true, message: '请输入你的投资额！' },
                             { min: 1, message: '长度不能少于1位！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 10) {
                                  callback('输入数值必须大于10');
                                } else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
            </a-col>

            <a-col :span="14">
              <a-form-item class="mb-10" label="选择你的打新API" :colon="false">
                <a-select v-decorator="[
                      'id',
                      {
                        rules: [{ required: true, message: '选择你的打新API' }]
                      }
                    ]">
                  <a-select-option v-for="option in apiOptions" :key="option.id" :id="option.id">
                    {{ option.api_name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="14">
              <a-form-item class="mb-10" label="杠杆设置" :colon="false">
                <a-input placeholder="1至50之间任意整数" style="width: 200px"
                         v-decorator="['leverage',{ rules: [
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 1) {
                                  callback('输入数值必须大于1');
                                } else if (numberValue > 50) {
                                  callback('输入数值必须小于50');
                                } else if (!/^\d+(\.\d+)?$/.test(value)) {
                                  callback('请输入标准的数字格式，不要包含非数字字符');
                                }else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
            </a-col>

            <a-col :span="14">
              <a-form-item class="mb-10" label="保证金模式" :colon="false">
                <a-radio-group v-model:value="mgnMode" button-style="solid">
                  <a-radio-button value="0">全仓</a-radio-button>
                  <a-radio-button value="1">逐仓</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="14">
              <a-form-item class="mb-10" label="新合约首次开仓方向" :colon="false">
                <a-radio-group v-model:value="first_order_set" button-style="solid">
                  <a-radio-button value="0">开多</a-radio-button>
                  <a-radio-button value="1">开空</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="14">
              <a-form-item class="mb-10" label="是否开启止盈止损" :colon="false">
                <a-radio-group v-model:value="trade_trigger_mode" button-style="solid">
                  <a-radio-button value="0">否</a-radio-button>
                  <a-radio-button value="1">是</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="14" v-if="trade_trigger_mode === '1'">
              <a-form-item class="mb-10" label="止盈百分比" :colon="false">
                <a-input placeholder="eg. 20（每笔交易盈利20%时止盈），0为不设置止盈" suffix="%"
                         v-decorator="['tp_trigger_px',{ rules: [
                             { required: true, message: '请输入你的止盈百分比！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 0) {
                                  callback('输入数值必须大于0');
                                }  else if (!/^\d+(\.\d+)?$/.test(value)) {
                                  callback('请输入标准的数字格式，不要包含非数字字符');
                                }else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
              <a-form-item class="mb-10" label="止损百分比" :colon="false">
                <a-input placeholder="eg. 20（每笔交易亏损20%时止损），0为不设置止损" suffix="%"
                         v-decorator="['sl_trigger_px',{ rules: [
                             { required: true, message: '请输入你的止损百分比！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 0) {
                                  callback('输入数值必须大于0');
                                }  else if (!/^\d+(\.\d+)?$/.test(value)) {
                                  callback('请输入标准的数字格式，不要包含非数字字符');
                                }else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
            </a-col>

            <a-col :span="14" v-if="trade_trigger_mode === '1'">
              <a-form-item class="mb-10" label="首单平仓后是否反手开单" :colon="false">
                <a-radio-group v-model:value="reverse_set" button-style="solid">
                  <a-radio-button value="0">否</a-radio-button>
                  <a-radio-button value="1">是</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="12" style="margin-top: 20px;">
              <a-button :loading="isLoading" type="primary" class="px-25" @click="submit()" style="width: 100%">创建打新任务</a-button>
            </a-col>

          </a-row>
        </a-form>
      </div>
    </a-card>
  </div>
</template>

<script>
import http from "@/utils/http";

const key = 'updatable';

export default {
  name: "ICO_Task",
  data() {
    return {
      // 提交状态
      isLoading: false,

      // 任务参数
      form: this.$form.createForm(this),
      apiOptions: [],  // 用于存储选项数据
      trader_platform: '',

      mgnMode: '0',  // 保证金模式
      trade_trigger_mode: '0',  // 止盈止损设置
      first_order_set: '0',  // 首单方向
      reverse_set: '0',  // 反手开单
    }
  },
  created() {
    this.fetchApiOptions();
    this.form.setFieldsValue({
      id: null, // 跟单 API ID
      investment: '0',  // 投资额
      leverage: '1',  // 自定义杠杆
      sl_trigger_px: '0',  // 止损百分比
      tp_trigger_px: '0',  // 止盈百分比
    })
  },
  methods: {
    // 获取跟单API信息
    async fetchApiOptions() {
      try {
        const response = await http.get('/api/apiadd/?token=' + localStorage.getItem("token"));
        console.log("API Response:", response.data);
        if (response.data && Array.isArray(response.data.data)) {
          this.user = response.data.data[0].user;
          this.apiOptions = response.data.data.filter(item => item.is_readonly === false).map(item => ({
            api_name: item.api_name,
            id: item.id
          }));
        } else {
          console.error("预期的数据格式不正确");
        }
      } catch (error) {
        console.error("获取数据失败:", error);
      }
    },

    // 提交任务信息
    submit() {
      this.isLoading = true;
      this.$message.loading({ content: '任务提交中，请稍后...', key, duration: 0 });
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            const data = {
              api: values.id,
              leverage: values.leverage,
              user: this.user, // 确保 this.user 已在组件中定义
              investment: values.investment,
              mgnMode: this.mgnMode,
              trade_trigger_mode: this.trade_trigger_mode,
              sl_trigger_px: values.sl_trigger_px,
              tp_trigger_px: values.tp_trigger_px,
              first_order_set: this.first_order_set,
              reverse_set: this.reverse_set,
            };

            const response = await http.post(
                '/api/ico/?token=' + localStorage.getItem('token'),
                data
            );
            if (response.data.code === 0) {
              this.$message.success({ content: '创建成功', key, duration: 2 });
              this.$router.push({path: '/ico-tasklist'});
            } else if (response.data.code === 1001) {
              this.$message.error({ content: response.data.error, key, duration: 2 });
              this.isLoading = false;
            } else {
              this.$message.error({ content: '创建失败', key, duration: 2 });
              this.isLoading = false;
            }
          } catch (error) {
            console.error({ content: error, key, duration: 2 });
            // 处理错误
            this.isLoading = false;
          }
        } else {
          // 处理表单验证错误
          console.error('表单验证错误:', err);
          this.$message.error({ content: '请检查表单输入是否正确', key, duration: 2 });
          this.isLoading = false;
        }
      });
    },
  }
}
</script>

<style lang="scss">

</style>